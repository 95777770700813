import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.35058 3.23999C8.30482 3.19366 8.25032 3.15688 8.19024 3.13178C8.13016 3.10668 8.0657 3.09375 8.00058 3.09375C7.93547 3.09375 7.871 3.10668 7.81092 3.13178C7.75084 3.15688 7.69634 3.19366 7.65058 3.23999C7.22558 3.65999 3.50058 7.39999 3.50058 9.83499C3.48127 11.0491 3.94408 12.2214 4.78759 13.0949C5.6311 13.9684 6.7865 14.4719 8.00058 14.495C9.21466 14.4719 10.3701 13.9684 11.2136 13.0949C12.0571 12.2214 12.5199 11.0491 12.5006 9.83499C12.5006 7.39999 8.77558 3.65999 8.35058 3.23999Z"
      fill="currentColor"
    />
    <path
      d="M5.89287 8.35261C6.94127 6.85547 9.12392 6.91259 10.0991 8.34578C9.6126 8.83311 9.12566 9.32057 8.63698 9.80993C9.1277 10.3029 9.61362 10.7911 10.1064 11.2863C9.58936 11.9765 8.90994 12.3615 8.04356 12.3749C7.14479 12.3888 6.43704 12.0056 5.89824 11.2943C6.39172 10.8011 6.87895 10.3143 7.36734 9.82606C6.86965 9.32871 6.38242 8.84183 5.89272 8.35261H5.89287ZM8.83919 8.3234C8.30926 8.00278 7.57899 8.04915 7.16658 8.32645C7.44535 8.60536 7.72513 8.88528 8.00129 9.16157C8.27672 8.88601 8.5565 8.60609 8.83919 8.3234ZM7.16265 11.2963C7.46074 11.5943 8.43157 11.5979 8.83106 11.3063C8.55127 11.0264 8.27032 10.7455 7.99184 10.4669C7.71961 10.7392 7.44113 11.0178 7.16265 11.2963Z"
      fill="#F5F5F5"
    />
    <path
      d="M10.1736 11.1684C9.97141 10.9657 9.77269 10.7674 9.57614 10.567C9.56437 10.5551 9.56132 10.5227 9.56844 10.5058C9.7615 10.0449 9.76136 9.58494 9.56873 9.12393C9.56103 9.10562 9.56539 9.07044 9.57832 9.05722C9.77181 8.86028 9.96764 8.66582 10.163 8.47092C10.1659 8.46801 10.1714 8.46787 10.1755 8.46641C10.6757 9.21346 10.7078 10.3486 10.1735 11.1684H10.1736Z"
      fill="#F5F5F5"
    />
    <path
      d="M5.82604 11.167C5.29974 10.3631 5.31659 9.22726 5.82517 8.46307C5.83941 8.47572 5.85408 8.4872 5.86715 8.50028C6.047 8.67992 6.22582 8.86057 6.40697 9.03861C6.43922 9.0703 6.44605 9.09413 6.42774 9.13759C6.23904 9.58553 6.2402 10.0354 6.42571 10.4837C6.44459 10.5294 6.43995 10.5549 6.40494 10.5892C6.21158 10.7791 6.02099 10.9718 5.82604 11.167Z"
      fill="#F5F5F5"
    />
    <path
      d="M16.0005 7.24002C16.0079 8.14546 15.7518 9.0335 15.2636 9.79606C14.7753 10.5586 14.0759 11.1628 13.2505 11.535C13.4157 10.9852 13.4999 10.4142 13.5005 9.84002C13.5005 7.29002 10.5005 3.99502 9.27051 2.74502C9.85868 2.01149 10.4863 1.31046 11.1505 0.64502C11.1963 0.598694 11.2508 0.561913 11.3108 0.536809C11.3709 0.511706 11.4354 0.498779 11.5005 0.498779C11.5656 0.498779 11.6301 0.511706 11.6902 0.536809C11.7503 0.561913 11.8047 0.598694 11.8505 0.64502C12.2755 1.06002 16.0005 4.80002 16.0005 7.24002V7.24002Z"
      fill="currentColor"
    />
    <path
      d="M2.50015 9.84002C2.50076 10.4142 2.58498 10.9852 2.75015 11.535C1.92474 11.1628 1.22534 10.5586 0.737092 9.79606C0.248845 9.0335 -0.00719046 8.14546 0.000153674 7.24002C0.000153674 4.80002 3.72515 1.06002 4.15015 0.64502C4.19591 0.598694 4.25041 0.561913 4.31049 0.536809C4.37057 0.511706 4.43504 0.498779 4.50015 0.498779C4.56527 0.498779 4.62973 0.511706 4.68982 0.536809C4.7499 0.561913 4.8044 0.598694 4.85015 0.64502C5.51439 1.31046 6.14198 2.01149 6.73015 2.74502C5.50015 3.99502 2.50015 7.29002 2.50015 9.84002Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCopy;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgMarket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.815 14.5H4.19C2.705 14.5 1.5 13.46 1.5 12.18V7.76001C1.5 7.6274 1.55268 7.50022 1.64645 7.40646C1.74021 7.31269 1.86739 7.26001 2 7.26001C2.13261 7.26001 2.25979 7.31269 2.35355 7.40646C2.44732 7.50022 2.5 7.6274 2.5 7.76001V12.18C2.5 12.91 3.255 13.5 4.19 13.5H9.815C10.745 13.5 11.5 12.91 11.5 12.18V7.76001C11.5 7.6274 11.5527 7.50022 11.6464 7.40646C11.7402 7.31269 11.8674 7.26001 12 7.26001C12.1326 7.26001 12.2598 7.31269 12.3536 7.40646C12.4473 7.50022 12.5 7.6274 12.5 7.76001V12.18C12.5 13.46 11.295 14.5 9.815 14.5Z"
      fill="currentColor"
    />
    <path
      d="M2.36494 8.26C2.05107 8.2544 1.74277 8.17607 1.46428 8.03119C1.18579 7.8863 0.944693 7.67881 0.759944 7.425C0.511101 7.0895 0.342873 6.70119 0.268303 6.29019C0.193733 5.87919 0.214825 5.45654 0.329945 5.055L1.18994 1.45C1.24752 1.18755 1.39081 0.951736 1.59722 0.779727C1.80363 0.607718 2.06142 0.509303 2.32994 0.5H3.99994C4.13255 0.5 4.25973 0.552678 4.3535 0.646447C4.44727 0.740215 4.49994 0.867392 4.49994 1V5.8C4.49994 7.155 3.53994 8.26 2.36494 8.26ZM2.32994 1.5C2.26994 1.5 2.18994 1.57 2.15494 1.7L1.29994 5.295C1.2286 5.54858 1.21492 5.81492 1.25991 6.07447C1.3049 6.33403 1.40742 6.58022 1.55994 6.795C1.65178 6.92594 1.77272 7.03381 1.91326 7.11015C2.05379 7.18649 2.21011 7.22923 2.36994 7.235C2.99994 7.26 3.49994 6.605 3.49994 5.8V1.5H2.32994Z"
      fill="currentColor"
    />
    <path
      d="M11.635 8.26C10.46 8.26 9.5 7.155 9.5 5.8V1C9.5 0.867392 9.55268 0.740215 9.64645 0.646447C9.74021 0.552678 9.86739 0.5 10 0.5H11.67C11.9362 0.509313 12.192 0.605873 12.398 0.774818C12.604 0.943763 12.7488 1.17573 12.81 1.435L13.67 5.055C13.7851 5.45654 13.8062 5.87919 13.7316 6.29019C13.6571 6.70119 13.4888 7.0895 13.24 7.425C13.0553 7.67881 12.8142 7.8863 12.5357 8.03119C12.2572 8.17607 11.9489 8.2544 11.635 8.26ZM10.5 1.5V5.8C10.5 6.605 11 7.26 11.635 7.26C11.7948 7.25423 11.9511 7.21149 12.0917 7.13515C12.2322 7.05881 12.3532 6.95094 12.445 6.82C12.5975 6.60522 12.7 6.35903 12.745 6.09947C12.79 5.83992 12.7763 5.57358 12.705 5.32L11.84 1.685C11.81 1.57 11.73 1.5 11.67 1.5H10.5Z"
      fill="currentColor"
    />
    <path
      d="M5.75003 8.26H5.25003C5.01937 8.26 4.79099 8.21441 4.57801 8.12584C4.36504 8.03727 4.17168 7.90747 4.00904 7.74391C3.84641 7.58034 3.71771 7.38624 3.63036 7.17277C3.543 6.95929 3.49871 6.73065 3.50003 6.5V1C3.50003 0.867392 3.55271 0.740215 3.64648 0.646447C3.74024 0.552678 3.86742 0.5 4.00003 0.5H7.00003C7.13264 0.5 7.25981 0.552678 7.35358 0.646447C7.44735 0.740215 7.50003 0.867392 7.50003 1V6.5C7.50135 6.73065 7.45705 6.95929 7.3697 7.17277C7.28234 7.38624 7.15365 7.58034 6.99101 7.74391C6.82838 7.90747 6.63502 8.03727 6.42204 8.12584C6.20907 8.21441 5.98069 8.26 5.75003 8.26ZM4.50003 1.5V6.5C4.50003 6.69891 4.57905 6.88968 4.7197 7.03033C4.86035 7.17098 5.05112 7.25 5.25003 7.25H5.75003C5.94894 7.25 6.13971 7.17098 6.28036 7.03033C6.42101 6.88968 6.50003 6.69891 6.50003 6.5V1.5H4.50003Z"
      fill="currentColor"
    />
    <path
      d="M8.75003 8.26H8.25003C8.01937 8.26 7.79099 8.21441 7.57801 8.12584C7.36504 8.03727 7.17168 7.90747 7.00904 7.74391C6.84641 7.58034 6.71771 7.38624 6.63036 7.17277C6.543 6.95929 6.49871 6.73065 6.50003 6.5V1C6.50003 0.867392 6.55271 0.740215 6.64648 0.646447C6.74024 0.552678 6.86742 0.5 7.00003 0.5H10C10.1326 0.5 10.2598 0.552678 10.3536 0.646447C10.4474 0.740215 10.5 0.867392 10.5 1V6.5C10.5013 6.73065 10.4571 6.95929 10.3697 7.17277C10.2823 7.38624 10.1536 7.58034 9.99101 7.74391C9.82838 7.90747 9.63502 8.03727 9.42204 8.12584C9.20907 8.21441 8.98069 8.26 8.75003 8.26ZM7.50003 1.5V6.5C7.50003 6.69891 7.57905 6.88968 7.7197 7.03033C7.86035 7.17098 8.05112 7.25 8.25003 7.25H8.75003C8.94894 7.25 9.13971 7.17098 9.28036 7.03033C9.42101 6.88968 9.50003 6.69891 9.50003 6.5V1.5H7.50003Z"
      fill="currentColor"
    />
    <path
      d="M8.5 14.5C8.36739 14.5 8.24021 14.4473 8.14645 14.3536C8.05268 14.2598 8 14.1326 8 14V11C8 10.765 8 10.5 7 10.5C6 10.5 6 10.765 6 11V14C6 14.1326 5.94732 14.2598 5.85355 14.3536C5.75979 14.4473 5.63261 14.5 5.5 14.5C5.36739 14.5 5.24021 14.4473 5.14645 14.3536C5.05268 14.2598 5 14.1326 5 14V11C5 10 5.675 9.5 7 9.5C8.325 9.5 9 10 9 11V14C9 14.1326 8.94732 14.2598 8.85355 14.3536C8.75979 14.4473 8.63261 14.5 8.5 14.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMarket;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_319_1899)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="white"
      />
      <path
        d="M15.2523 6.21475L16.25 5.26134V5.05225H12.7943L10.3318 11.1807L7.53068 5.05225H3.90795V5.26134L5.07273 6.66361C5.12833 6.71499 5.17123 6.77859 5.19805 6.84938C5.22487 6.92018 5.23488 6.99624 5.22727 7.07156V12.5829C5.24466 12.681 5.23848 12.7818 5.20924 12.8771C5.18 12.9723 5.12853 13.0592 5.05909 13.1307L3.75 14.7204V14.9272H7.47159V14.7182L6.15909 13.1272C6.08893 13.056 6.03613 12.9695 6.0048 12.8745C5.97346 12.7795 5.96443 12.6786 5.97841 12.5795V7.81588L9.24432 14.9295H9.625L12.433 7.81588V13.4829C12.433 13.6318 12.433 13.6625 12.3352 13.7613L11.325 14.7386V14.9477H16.2273V14.7386L15.2534 13.7852C15.2109 13.7535 15.1779 13.7109 15.1578 13.6618C15.1378 13.6128 15.1316 13.5591 15.1398 13.5068V6.49316C15.1314 6.44079 15.1376 6.38715 15.1577 6.33806C15.1777 6.28897 15.2108 6.24631 15.2534 6.21475H15.2523Z"
        fill="#50565F"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_1899">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgMedium;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.25 14.5H11.75C11.1537 14.4987 10.5821 14.2613 10.1605 13.8396C9.7388 13.4179 9.50132 12.8464 9.5 12.25V10.75C9.5 10.4185 9.3683 10.1006 9.13388 9.86617C8.89946 9.63175 8.58152 9.50005 8.25 9.50005H7.75C7.41848 9.50005 7.10054 9.63175 6.86612 9.86617C6.6317 10.1006 6.5 10.4185 6.5 10.75V12.25C6.49868 12.8464 6.2612 13.4179 5.83953 13.8396C5.41786 14.2613 4.84633 14.4987 4.25 14.5H2.75C2.15367 14.4987 1.58214 14.2613 1.16047 13.8396C0.738798 13.4179 0.501321 12.8464 0.5 12.25V7.40005C0.499785 7.04771 0.582501 6.70027 0.741458 6.38583C0.900416 6.07139 1.13115 5.79878 1.415 5.59005L6.645 0.85505L6.69 0.82005C7.07232 0.547184 7.5303 0.400513 8 0.400513C8.4697 0.400513 8.92768 0.547184 9.31 0.82005L9.355 0.85505L14.585 5.59005C14.8688 5.79878 15.0996 6.07139 15.2585 6.38583C15.4175 6.70027 15.5002 7.04771 15.5 7.40005V12.25C15.4987 12.8464 15.2612 13.4179 14.8395 13.8396C14.4179 14.2613 13.8463 14.4987 13.25 14.5ZM7.75 8.50005H8.25C8.84633 8.50137 9.41786 8.73885 9.83953 9.16052C10.2612 9.58219 10.4987 10.1537 10.5 10.75V12.25C10.5 12.5816 10.6317 12.8995 10.8661 13.1339C11.1005 13.3684 11.4185 13.5 11.75 13.5H13.25C13.5815 13.5 13.8995 13.3684 14.1339 13.1339C14.3683 12.8995 14.5 12.5816 14.5 12.25V7.40005C14.4979 7.2037 14.4492 7.01066 14.358 6.83681C14.2667 6.66295 14.1354 6.51326 13.975 6.40005L13.93 6.36005L8.705 1.62005C8.49727 1.47817 8.25156 1.40227 8 1.40227C7.74844 1.40227 7.50273 1.47817 7.295 1.62005L2.07 6.34505L2.025 6.38505C1.86918 6.50094 1.74275 6.65181 1.6559 6.8255C1.56905 6.99919 1.52422 7.19086 1.525 7.38505V12.235C1.52098 12.5649 1.64748 12.8829 1.87694 13.1199C2.1064 13.3568 2.42022 13.4935 2.75 13.5H4.25C4.58152 13.5 4.89946 13.3684 5.13388 13.1339C5.3683 12.8995 5.5 12.5816 5.5 12.25V10.75C5.50132 10.1537 5.7388 9.58219 6.16047 9.16052C6.58214 8.73885 7.15367 8.50137 7.75 8.50005Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboard;

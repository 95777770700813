import * as React from 'react';
import { SVGProps } from 'react';

const SvgGithub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_319_1908)">
      <path
        d="M10 0C4.50745 0 0 4.50745 0 10C0 14.687 3.28888 18.9447 7.65625 20V16.6916C7.22351 16.7863 6.82526 16.7891 6.38474 16.6592C5.79376 16.4848 5.31357 16.0912 4.95712 15.4909C4.72992 15.1076 4.32724 14.6919 3.90717 14.7224L3.80417 13.5551C4.71252 13.4773 5.49835 14.1086 5.96481 14.8923C6.17203 15.2408 6.41052 15.4451 6.71661 15.5354C7.01248 15.6226 7.33017 15.5807 7.70035 15.45C7.79327 14.7089 8.13278 14.4315 8.38928 14.0408V14.0402C5.78461 13.6517 4.74655 12.2699 4.33456 11.1792C3.7886 9.73099 4.08157 7.92175 5.047 6.77841C5.06577 6.75613 5.09964 6.69785 5.08652 6.6571C4.64386 5.31998 5.18326 4.21387 5.20309 4.09653C5.71396 4.24759 5.79697 3.94455 7.42218 4.93195L7.70309 5.10071C7.82059 5.17075 7.78366 5.13077 7.90115 5.12192C8.57971 4.93759 9.29504 4.83582 9.99985 4.82666C10.71 4.83582 11.4207 4.93759 12.1274 5.12939L12.2183 5.13855C12.2104 5.13733 12.243 5.13275 12.2978 5.1001C14.328 3.87039 14.2551 4.27231 14.7995 4.09531C14.8192 4.2128 15.3514 5.33691 14.9135 6.6571C14.8544 6.83899 16.6736 8.50479 15.6653 11.1787C15.2533 12.2699 14.2154 13.6517 11.6107 14.0402V14.0408C11.9446 14.5497 12.3457 14.8204 12.3436 15.8702V20C16.7111 18.9447 19.9998 14.687 19.9998 10C20 4.50745 15.4926 0 10 0V0Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_1908">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGithub;

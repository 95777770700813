import { Token } from 'types';

import { MAINNET_TOKENS } from '../common/mainnet';

const { bayc, mayc, wpunks, ...rest } = MAINNET_TOKENS;
// List adapted from Uniswap's repository:
// https://github.com/Uniswap/default-token-list/blob/main/src/tokens/mainnet.json
export const MAINNET_UNISWAP_TOKENS = {
  ...rest,
  weth: {
    id: 'weth',
    symbol: 'WETH',
    decimals: 18,
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    asset:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  } as Token,
  // dai: {
  //   id: 'dai',
  //   symbol: 'DAI',
  //   decimals: 18,
  //   address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
  // } as Token,
  // zrx: {
  //   id: 'zrx',
  //   symbol: 'ZRX',
  //   decimals: 18,
  //   address: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png',
  // } as Token,
  // crv: {
  //   id: 'crv',
  //   symbol: 'CRV',
  //   decimals: 18,
  //   address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xD533a949740bb3306d119CC777fa900bA034cd52/logo.png',
  // } as Token,
  // uni: {
  //   id: 'uni',
  //   symbol: 'UNI',
  //   decimals: 18,
  //   address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  //   asset: 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
  // } as Token,
  // usdt: {
  //   id: 'usdt',
  //   symbol: 'USDT',
  //   decimals: 6,
  //   address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
  // } as Token,
  // usdc: {
  //   id: 'usdc',
  //   symbol: 'USDC',
  //   decimals: 6,
  //   address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  // } as Token,
  // oxt: {
  //   id: 'oxt',
  //   symbol: 'OXT',
  //   decimals: 18,
  //   address: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png',
  // } as Token,
  // mkr: {
  //   id: 'mkr',
  //   symbol: 'MKR',
  //   decimals: 18,
  //   address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png',
  // } as Token,
  // link: {
  //   id: 'link',
  //   symbol: 'LINK',
  //   decimals: 18,
  //   address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png',
  // } as Token,
  // rep: {
  //   id: 'rep',
  //   symbol: 'REP',
  //   decimals: 18,
  //   address: '0x1985365e9f78359a9B6AD760e32412f4a445E862',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1985365e9f78359a9B6AD760e32412f4a445E862/logo.png',
  // } as Token,
  // repv2: {
  //   id: 'repv2',
  //   symbol: 'REPv2',
  //   decimals: 18,
  //   address: '0x221657776846890989a759BA2973e427DfF5C9bB',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x221657776846890989a759BA2973e427DfF5C9bB/logo.png',
  // } as Token,
  // knc: {
  //   id: 'knc',
  //   symbol: 'KNC',
  //   decimals: 18,
  //   address: '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png',
  // } as Token,
  // comp: {
  //   id: 'comp',
  //   symbol: 'COMP',
  //   decimals: 18,
  //   address: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc00e94Cb662C3520282E6f5717214004A7f26888/logo.png',
  // } as Token,
  // band: {
  //   id: 'band',
  //   symbol: 'BAND',
  //   decimals: 18,
  //   address: '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
  //   asset: 'https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326',
  // } as Token,
  // nmr: {
  //   id: 'nmr',
  //   symbol: 'NMR',
  //   decimals: 18,
  //   address: '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png',
  // } as Token,
  // uma: {
  //   id: 'uma',
  //   symbol: 'UMA',
  //   decimals: 18,
  //   address: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828/logo.png',
  // } as Token,
  // lrc: {
  //   id: 'lrc',
  //   symbol: 'LRC',
  //   decimals: 18,
  //   address: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png',
  // } as Token,
  // yfi: {
  //   id: 'yfi',
  //   symbol: 'YFI',
  //   decimals: 18,
  //   address: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  //   asset: 'https://assets.coingecko.com/coins/images/11849/thumb/yfi-192x192.png?1598325330',
  // } as Token,
  // ren: {
  //   id: 'ren',
  //   symbol: 'REN',
  //   decimals: 18,
  //   address: '0x408e41876cCCDC0F92210600ef50372656052a38',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png',
  // } as Token,
  // wbtc: {
  //   id: 'wbtc',
  //   symbol: 'WBTC',
  //   decimals: 8,
  //   address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
  // } as Token,
  // bal: {
  //   id: 'bal',
  //   address: '0xba100000625a3754423978a60c9317c58a424e3D',
  //   symbol: 'BAL',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png',
  // } as Token,
  // nu: {
  //   id: 'nu',
  //   address: '0x4fE83213D56308330EC302a8BD641f1d0113A4Cc',
  //   symbol: 'NU',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg?1547037916',
  // } as Token,
  // aave: {
  //   id: 'aave',
  //   address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
  //   symbol: 'AAVE',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110',
  // } as Token,
  // grt: {
  //   id: 'grt',
  //   address: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
  //   symbol: 'GRT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566',
  // } as Token,
  // bnt: {
  //   id: 'bnt',
  //   address: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
  //   symbol: 'BNT',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png',
  // } as Token,
  // snx: {
  //   id: 'snx',
  //   address: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
  //   symbol: 'SNX',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F/logo.png',
  // } as Token,
  // maba: {
  //   id: 'maba',
  //   address: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
  //   symbol: 'MANA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png?1550108745',
  // } as Token,
  // loom: {
  //   id: 'loom',
  //   address: '0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0',
  //   symbol: 'LOOM',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0/logo.png',
  // } as Token,
  // cvc: {
  //   id: 'cvc',
  //   address: '0x41e5560054824eA6B0732E656E3Ad64E20e94E45',
  //   symbol: 'CVC',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556',
  // } as Token,
  // dnt: {
  //   id: 'dnt',
  //   address: '0x0AbdAce70D3790235af448C88547603b945604ea',
  //   symbol: 'DNT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762',
  // } as Token,
  // storj: {
  //   id: 'storj',
  //   address: '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
  //   symbol: 'STORJ',
  //   decimals: 8,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png',
  // } as Token,
  // amp: {
  //   id: 'amp',
  //   address: '0xfF20817765cB7f73d4bde2e66e067E58D11095C2',
  //   symbol: 'AMP',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
  // } as Token,
  // gno: {
  //   id: 'gno',
  //   address: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
  //   symbol: 'GNO',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png',
  // } as Token,
  // ant: {
  //   id: 'ant',
  //   address: '0xa117000000f279D81A1D3cc75430fAA017FA5A2e',
  //   symbol: 'ANT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/681/thumb/JelZ58cv_400x400.png?1601449653',
  // } as Token,
  // keep: {
  //   id: 'keep',
  //   address: '0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC',
  //   symbol: 'KEEP',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336',
  // } as Token,
  // tbtc: {
  //   id: 'tbtc',
  //   address: '0x18084fbA666a33d37592fA2633fD49a74DD93a88',
  //   symbol: 'tBTC',
  //   decimals: 18,
  //   asset:
  //     'https://raw.githubusercontent.com/uniswap/assets/master/blockchains/ethereum/assets/0x18084fbA666a33d37592fA2633fD49a74DD93a88/logo.png',
  // } as Token,
  // mln: {
  //   id: 'mln',
  //   address: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
  //   symbol: 'MLN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/605/thumb/melon.png?1547034295',
  // } as Token,
  // ens: {
  //   id: 'ens',
  //   address: '0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72',
  //   symbol: 'ENS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140',
  // } as Token,
  // susd: {
  //   id: 'susd',
  //   address: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
  //   symbol: 'sUSD',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765',
  // } as Token,
  // matic: {
  //   id: 'matic',
  //   address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
  //   symbol: 'MATIC',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912',
  // } as Token,
  // ape: {
  //   id: 'ape',
  //   address: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
  //   symbol: 'APE',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455',
  // } as Token,
  // inch: {
  //   id: '1inch',
  //   address: '0x111111111117dC0aa78b770fA6A738034120C302',
  //   symbol: '1INCH',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028',
  // } as Token,
  // aergo: {
  //   id: 'aergo',
  //   address: '0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6',
  //   symbol: 'AERGO',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4490/thumb/aergo.png?1647696770',
  // } as Token,
  // aioz: {
  //   id: 'aioz',
  //   address: '0x626E8036dEB333b408Be468F951bdB42433cBF18',
  //   symbol: 'AIOZ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14631/thumb/aioz_logo.png?1617413126',
  // } as Token,
  // alcx: {
  //   id: 'alcx',
  //   address: '0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF',
  //   symbol: 'ALCX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png?1614409874',
  // } as Token,
  // avt: {
  //   id: 'avt',
  //   address: '0x845576c64f9754CF09d87e45B720E82F3EeF522C',
  //   symbol: 'AVT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/19727/thumb/ewnektoB_400x400.png?1635767094',
  // } as Token,
  // agld: {
  //   id: 'agld',
  //   address: '0x32353A6C91143bfd6C7d363B546e62a9A2489A20',
  //   symbol: 'AGLD',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg?1630570955',
  // } as Token,
  // bond: {
  //   id: 'bond',
  //   address: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
  //   symbol: 'BOND',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12811/thumb/barnbridge.jpg?1602728853',
  // } as Token,
  // forth: {
  //   id: 'forth',
  //   address: '0x77FbA179C79De5B7653F68b5039Af940AdA60ce0',
  //   symbol: 'FORTH',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg?1619020835',
  // } as Token,
  // ankr: {
  //   id: 'ankr',
  //   address: '0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4',
  //   symbol: 'ANKR',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978',
  // } as Token,
  // api3: {
  //   id: 'api3',
  //   address: '0x0b38210ea11411557c13457D4dA7dC6ea731B88a',
  //   symbol: 'API3',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg?1606751424',
  // } as Token,
  // arpa: {
  //   id: 'arpa',
  //   address: '0xBA50933C268F567BDC86E1aC131BE072C6B0b71a',
  //   symbol: 'ARPA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357',
  // } as Token,
  // asm: {
  //   id: 'asm',
  //   address: '0x2565ae0385659badCada1031DB704442E1b69982',
  //   symbol: 'ASM',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg?1591775789',
  // } as Token,
  // clv: {
  //   id: 'clv',
  //   address: '0x80C62FE4487E1351b47Ba49809EBD60ED085bf52',
  //   symbol: 'CLV',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15278/thumb/clover.png?1645084454',
  // } as Token,
  // ctx: {
  //   id: 'ctx',
  //   address: '0x321C2fE4446C7c963dc41Dd58879AF648838f98D',
  //   symbol: 'CTX',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/14932/thumb/glossy_icon_-_C200px.png?1619073171',
  // } as Token,
  // axs: {
  //   id: 'axs',
  //   address: '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
  //   symbol: 'AXS',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082',
  // } as Token,
  // badger: {
  //   id: 'badger',
  //   address: '0x3472A5A71965499acd81997a54BBA8D852C6E53d',
  //   symbol: 'BADGER',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976',
  // } as Token,
  // cro: {
  //   id: 'cro',
  //   address: '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
  //   symbol: 'CRO',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042',
  // } as Token,
  // bat: {
  //   id: 'bat',
  //   address: '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
  //   symbol: 'BAT',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427',
  // } as Token,
  // bico: {
  //   id: 'bico',
  //   address: '0xF17e65822b568B3903685a7c9F496CF7656Cc6C2',
  //   symbol: 'BICO',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg?1638269749',
  // } as Token,
  // gtc: {
  //   id: 'gtc',
  //   address: '0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F',
  //   symbol: 'GTC',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15810/thumb/gitcoin.png?1621992929',
  // } as Token,
  // imx: {
  //   id: 'imx',
  //   address: '0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF',
  //   symbol: 'IMX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17233/thumb/imx.png?1636691817',
  // } as Token,
  // blz: {
  //   id: 'blz',
  //   address: '0x5732046A883704404F284Ce41FfADd5b007FD668',
  //   symbol: 'BLZ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510',
  // } as Token,
  // mask: {
  //   id: 'mask',
  //   address: '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074',
  //   symbol: 'MASK',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316',
  // } as Token,
  // auction: {
  //   id: 'auction',
  //   address: '0xA9B1Eb5908CfC3cdf91F9B8B3a74108598009096',
  //   symbol: 'AUCTION',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13860/thumb/1_KtgpRIJzuwfHe0Rl0avP_g.jpeg?1612412025',
  // } as Token,
  // btrst: {
  //   id: 'btrst',
  //   address: '0x799ebfABE77a6E34311eeEe9825190B9ECe32824',
  //   symbol: 'BTRST',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/18100/thumb/braintrust.PNG?1630475394',
  // } as Token,
  // pla: {
  //   id: 'pla',
  //   address: '0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430',
  //   symbol: 'PLA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14316/thumb/54023228.png?1615366911',
  // } as Token,
  // ctsi: {
  //   id: 'ctsi',
  //   address: '0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D',
  //   symbol: 'CTSI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021',
  // } as Token,
  // plu: {
  //   id: 'plu',
  //   address: '0xD8912C10681D8B21Fd3742244f44658dBA12264E',
  //   symbol: 'PLU',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1241/thumb/pluton.png?1548331624',
  // } as Token,
  // chz: {
  //   id: 'chz',
  //   address: '0x3506424F91fD33084466F402d5D97f05F8e3b4AF',
  //   symbol: 'CHZ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/8834/thumb/Chiliz.png?1561970540',
  // } as Token,
  // nct: {
  //   id: 'nct',
  //   address: '0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1',
  //   symbol: 'NCT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2843/thumb/ImcYCVfX_400x400.jpg?1628519767',
  // } as Token,
  // rai: {
  //   id: 'rai',
  //   address: '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
  //   symbol: 'RAI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334',
  // } as Token,
  // coti: {
  //   id: 'coti',
  //   address: '0xDDB3422497E61e13543BeA06989C0789117555c5',
  //   symbol: 'COTI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2962/thumb/Coti.png?1559653863',
  // } as Token,
  // sol: {
  //   id: 'sol',
  //   address: '0xD31a59c85aE9D8edEFeC411D448f90841571b89c',
  //   symbol: 'SOL',
  //   decimals: 9,
  //   asset: 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
  // } as Token,
  // crpt: {
  //   id: 'crpt',
  //   address: '0x08389495D7456E1951ddF7c3a1314A4bfb646d8B',
  //   symbol: 'CRPT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205',
  // } as Token,
  // super: {
  //   id: 'super',
  //   address: '0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55',
  //   symbol: 'SUPER',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14040/thumb/6YPdWn6.png?1613975899',
  // } as Token,
  // syn: {
  //   id: 'syn',
  //   address: '0x0f2D719407FdBeFF09D87557AbB7232601FD9F29',
  //   symbol: 'SYN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/18024/thumb/syn.png?1635002049',
  // } as Token,
  // tru: {
  //   id: 'tru',
  //   address: '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
  //   symbol: 'TRU',
  //   decimals: 8,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13180/thumb/truefi_glyph_color.png?1617610941',
  // } as Token,
  // ddx: {
  //   id: 'ddx',
  //   address: '0x3A880652F47bFaa771908C07Dd8673A787dAEd3A',
  //   symbol: 'DDX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png?1608741641',
  // } as Token,
  // yfii: {
  //   id: 'yfii',
  //   address: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
  //   symbol: 'YFII',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png?1598677348',
  // } as Token,
  // dia: {
  //   id: 'dia',
  //   address: '0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419',
  //   symbol: 'DIA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751',
  // } as Token,
  // rbc: {
  //   id: 'rbc',
  //   address: '0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3',
  //   symbol: 'RBC',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509',
  // } as Token,
  // enj: {
  //   id: 'enj',
  //   address: '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
  //   symbol: 'ENJ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078',
  // } as Token,
  // rare: {
  //   id: 'rare',
  //   address: '0xba5BDe662c17e2aDFF1075610382B9B691296350',
  //   symbol: 'RARE',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17753/thumb/RARE.jpg?1629220534',
  // } as Token,
  // toke: {
  //   id: 'toke',
  //   address: '0x2e9d63788249371f1DFC918a52f8d799F4a38C94',
  //   symbol: 'TOKE',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/17495/thumb/tokemak-avatar-200px-black.png?1628131614',
  // } as Token,
  // ern: {
  //   id: 'ern',
  //   address: '0xBBc2AE13b23d715c30720F079fcd9B4a74093505',
  //   symbol: 'ERN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png?1647831402',
  // } as Token,
  // fet: {
  //   id: 'fet',
  //   address: '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
  //   symbol: 'FET',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136',
  // } as Token,
  // fx: {
  //   id: 'fx',
  //   address: '0x8c15Ef5b4B21951d50E53E4fbdA8298FFAD25057',
  //   symbol: 'FX',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683',
  // } as Token,
  // gods: {
  //   id: 'gods',
  //   address: '0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97',
  //   symbol: 'GODS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17139/thumb/10631.png?1635718182',
  // } as Token,
  // gfi: {
  //   id: 'gfi',
  //   address: '0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b',
  //   symbol: 'GFI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/19081/thumb/GOLDFINCH.png?1634369662',
  // } as Token,
  // glm: {
  //   id: 'glm',
  //   address: '0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429',
  //   symbol: 'GLM',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png?1606392013',
  // } as Token,
  // gyen: {
  //   id: 'gyen',
  //   address: '0xC08512927D12348F6620a698105e1BAac6EcD911',
  //   symbol: 'GYEN',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png?1614843343',
  // } as Token,
  // farm: {
  //   id: 'farm',
  //   address: '0xa0246c9032bC3A600820415aE600c6388619A14D',
  //   symbol: 'FARM',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180',
  // } as Token,
  // high: {
  //   id: 'high',
  //   address: '0x71Ab77b7dbB4fa7e017BC15090b2163221420282',
  //   symbol: 'HIGH',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470',
  // } as Token,
  // idex: {
  //   id: 'idex',
  //   address: '0xB705268213D593B8FD88d3FDEFF93AFF5CbDcfAE',
  //   symbol: 'IDEX',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/2565/thumb/logomark-purple-286x286.png?1638362736',
  // } as Token,
  // rlc: {
  //   id: 'rlc',
  //   address: '0x607F4C5BB672230e8672085532f7e901544a7375',
  //   symbol: 'RLC',
  //   decimals: 9,
  //   asset: 'https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png?1604543202',
  // } as Token,
  // inv: {
  //   id: 'inv',
  //   address: '0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68',
  //   symbol: 'INV',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg?1614921871',
  // } as Token,
  // jasmy: {
  //   id: 'jasmy',
  //   address: '0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC',
  //   symbol: 'JASMY',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg?1612473259',
  // } as Token,
  // krl: {
  //   id: 'krl',
  //   address: '0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0',
  //   symbol: 'KRL',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979',
  // } as Token,
  // lcx: {
  //   id: 'lcx',
  //   address: '0x037A54AaB062628C9Bbae1FDB1583c195585fe41',
  //   symbol: 'LCX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg?1574327008',
  // } as Token,
  // lqty: {
  //   id: 'lqty',
  //   address: '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
  //   symbol: 'LQTY',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png?1617631180',
  // } as Token,
  // lpt: {
  //   id: 'lpt',
  //   address: '0x58b6A8A3302369DAEc383334672404Ee733aB239',
  //   symbol: 'LPT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365',
  // } as Token,
  // mpl: {
  //   id: 'mpl',
  //   address: '0x33349B282065b0284d756F0577FB39c158F935e6',
  //   symbol: 'MPL',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/14097/thumb/photo_2021-05-03_14.20.41.jpeg?1620022863',
  // } as Token,
  // mdt: {
  //   id: 'mdt',
  //   address: '0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26',
  //   symbol: 'MDT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2441/thumb/mdt_logo.png?1569813574',
  // } as Token,
  // mir: {
  //   id: 'mir',
  //   address: '0x09a3EcAFa817268f77BE1283176B946C4ff2E608',
  //   symbol: 'MIR',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658',
  // } as Token,
  // mco2: {
  //   id: 'mco2',
  //   address: '0xfC98e825A2264D890F9a1e68ed50E1526abCcacD',
  //   symbol: 'MCO2',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg?1615948522',
  // } as Token,
  // musd: {
  //   id: 'musd',
  //   address: '0xe2f2a5C287993345a840Db3B0845fbC70f5935a5',
  //   symbol: 'MUSD',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11576/thumb/mStable_USD.png?1595591803',
  // } as Token,
  // omg: {
  //   id: 'omg',
  //   address: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
  //   symbol: 'OMG',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg?1591167168',
  // } as Token,
  // ogn: {
  //   id: 'ogn',
  //   address: '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
  //   symbol: 'OGN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/3296/thumb/op.jpg?1547037878',
  // } as Token,
  // trac: {
  //   id: 'trac',
  //   address: '0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F',
  //   symbol: 'TRAC',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg?1635134367',
  // } as Token,
  // orn: {
  //   id: 'orn',
  //   address: '0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a',
  //   symbol: 'ORN',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png?1594943318',
  // } as Token,
  // upi: {
  //   id: 'upi',
  //   address: '0x70D2b7C19352bB76e4409858FF5746e500f2B67c',
  //   symbol: 'UPI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008',
  // } as Token,
  // perp: {
  //   id: 'perp',
  //   address: '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
  //   symbol: 'PERP',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771',
  // } as Token,
  // iotx: {
  //   id: 'iotx',
  //   address: '0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69',
  //   symbol: 'IOTX',
  //   decimals: 18,
  //   asset: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png',
  // } as Token,
  // pols: {
  //   id: 'pols',
  //   address: '0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa',
  //   symbol: 'POLS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702',
  // } as Token,
  // poly: {
  //   id: 'poly',
  //   address: '0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC',
  //   symbol: 'POLY',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2784/thumb/inKkF01.png?1605007034',
  // } as Token,
  // pro: {
  //   id: 'pro',
  //   address: '0x226bb599a12C826476e3A771454697EA52E9E220',
  //   symbol: 'PRO',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100',
  // } as Token,
  // qnt: {
  //   id: 'qnt',
  //   address: '0x4a220E6096B25EADb88358cb44068A3248254675',
  //   symbol: 'QNT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg?1612437252',
  // } as Token,
  // qsp: {
  //   id: 'qsp',
  //   address: '0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d',
  //   symbol: 'QSP',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917',
  // } as Token,
  // quick: {
  //   id: 'quick',
  //   address: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f',
  //   symbol: 'QUICK',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659',
  // } as Token,
  // rad: {
  //   id: 'rad',
  //   address: '0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3',
  //   symbol: 'RAD',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14013/thumb/radicle.png?1614402918',
  // } as Token,
  // nkn: {
  //   id: 'nkn',
  //   address: '0x5Cf04716BA20127F1E2297AdDCf4B5035000c9eb',
  //   symbol: 'NKN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/3375/thumb/nkn.png?1548329212',
  // } as Token,
  // rly: {
  //   id: 'rly',
  //   address: '0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b',
  //   symbol: 'RLY',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12843/thumb/image.png?1611212077',
  // } as Token,
  // rgt: {
  //   id: 'rgt',
  //   address: '0xD291E7a03283640FDc51b121aC401383A46cC623',
  //   symbol: 'RGT',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014',
  // } as Token,
  // rari: {
  //   id: 'rari',
  //   address: '0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF',
  //   symbol: 'RARI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11845/thumb/Rari.png?1594946953',
  // } as Token,
  // rndr: {
  //   id: 'rndr',
  //   address: '0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24',
  //   symbol: 'RNDR',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11636/thumb/rndr.png?1638840934',
  // } as Token,
  // req: {
  //   id: 'req',
  //   address: '0x8f8221aFbB33998d8584A2B05749bA73c37a938a',
  //   symbol: 'REQ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png?1643250951',
  // } as Token,
  // rbn: {
  //   id: 'rbn',
  //   address: '0x6123B0049F904d730dB3C36a31167D9d4121fA6B',
  //   symbol: 'RBN',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15823/thumb/RBN_64x64.png?1633529723',
  // } as Token,
  // fox: {
  //   id: 'fox',
  //   address: '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d',
  //   symbol: 'FOX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/9988/thumb/FOX.png?1574330622',
  // } as Token,
  // shib: {
  //   id: 'shib',
  //   address: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
  //   symbol: 'SHIB',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11939/thumb/shiba.png?1622619446',
  // } as Token,
  // shping: {
  //   id: 'shping',
  //   address: '0x7C84e62859D0715eb77d1b1C4154Ecd6aBB21BEC',
  //   symbol: 'SHPING',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2588/thumb/r_yabKKi_400x400.jpg?1639470164',
  // } as Token,
  // skl: {
  //   id: 'skl',
  //   address: '0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7',
  //   symbol: 'SKL',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png?1606789574',
  // } as Token,
  // orca: {
  //   id: 'orca',
  //   address: '0x6F59e0461Ae5E2799F1fB3847f05a63B16d0DbF8',
  //   symbol: 'ORCA',
  //   decimals: 18,
  //   asset: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5183.png',
  // } as Token,
  // spell: {
  //   id: 'spell',
  //   address: '0x090185f2135308BaD17527004364eBcC2D37e5F6',
  //   symbol: 'SPELL',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png?1622544862',
  // } as Token,
  // stx: {
  //   id: 'stx',
  //   address: '0x006BeA43Baa3f7A6f765F14f10A1a1b08334EF45',
  //   symbol: 'STX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1230/thumb/stox-token.png?1547035256',
  // } as Token,
  // snt: {
  //   id: 'snt',
  //   address: '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
  //   symbol: 'SNT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/779/thumb/status.png?1548610778',
  // } as Token,
  // pax: {
  //   id: 'pax',
  //   address: '0xc1D204d77861dEf49b6E769347a883B15EC397Ff',
  //   symbol: 'PAX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/1601/thumb/pax.png?1547035800',
  // } as Token,
  // suku: {
  //   id: 'suku',
  //   address: '0x0763fdCCF1aE541A5961815C0872A8c5Bc6DE4d7',
  //   symbol: 'SUKU',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11969/thumb/UmfW5S6f_400x400.jpg?1596602238',
  // } as Token,
  // sushi: {
  //   id: 'sushi',
  //   address: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
  //   symbol: 'SUSHI',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688',
  // } as Token,
  // trb: {
  //   id: 'trb',
  //   address: '0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0',
  //   symbol: 'TRB',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png?1584980686',
  // } as Token,
  // tribe: {
  //   id: 'tribe',
  //   address: '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
  //   symbol: 'TRIBE',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG?1617487954',
  // } as Token,
  // unfi: {
  //   id: 'unfi',
  //   address: '0x441761326490cACF7aF299725B6292597EE822c2',
  //   symbol: 'UNFI',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967',
  // } as Token,
  // vgx: {
  //   id: 'vgx',
  //   address: '0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d',
  //   symbol: 'VGX',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png?1575693595',
  // } as Token,
  // xyo: {
  //   id: 'xyo',
  //   address: '0x55296f69f40Ea6d20E478533C15A6B08B654E758',
  //   symbol: 'XYO',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png?1547039819',
  // } as Token,
  // slp: {
  //   id: 'slp',
  //   address: '0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25',
  //   symbol: 'SLP',
  //   decimals: 0,
  //   asset: 'https://assets.coingecko.com/coins/images/10366/thumb/SLP.png?1578640057',
  // } as Token,
  // cvx: {
  //   id: 'cvx',
  //   address: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
  //   symbol: 'CVX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15585/thumb/convex.png?1621256328',
  // } as Token,
  // dydx: {
  //   id: 'dydx',
  //   address: '0x92D6C1e31e14520e676a687F0a93788B716BEff5',
  //   symbol: 'DYDX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17500/thumb/hjnIm9bV.jpg?1628009360',
  // } as Token,
  // fxs: {
  //   id: 'fxs',
  //   address: '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  //   symbol: 'FXS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989',
  // } as Token,
  // kp3r: {
  //   id: 'kp3r',
  //   address: '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
  //   symbol: 'KP3R',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg?1607057458',
  // } as Token,
  // multi: {
  //   id: 'multi',
  //   address: '0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4',
  //   symbol: 'MULTI',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
  // } as Token,
  // ocean: {
  //   id: 'ocean',
  //   address: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
  //   symbol: 'OCEAN',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg?1547038686',
  // } as Token,
  // paxg: {
  //   id: 'paxg',
  //   address: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
  //   symbol: 'PAXG',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565',
  // } as Token,
  // ach: {
  //   id: 'ach',
  //   address: '0xEd04915c23f00A313a544955524EB7DBD823143d',
  //   symbol: 'ACH',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266',
  // } as Token,
  // coval: {
  //   id: 'coval',
  //   address: '0x3D658390460295FB963f54dC0899cfb1c30776Df',
  //   symbol: 'COVAL',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
  // } as Token,
  // sand: {
  //   id: 'sand',
  //   address: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
  //   symbol: 'SAND',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942',
  // } as Token,
  // gusd: {
  //   id: 'gusd',
  //   address: '0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd',
  //   symbol: 'GUSD',
  //   decimals: 2,
  //   asset: 'https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png?1536745278',
  // } as Token,
  // audio: {
  //   id: 'audio',
  //   address: '0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998',
  //   symbol: 'AUDIO',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12913/thumb/AudiusCoinLogo_2x.png?1603425727',
  // } as Token,
  // ash: {
  //   id: 'ash',
  //   address: '0x64D91f12Ece7362F91A6f8E7940Cd55F05060b92',
  //   symbol: 'ASH',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15714/thumb/omnPqaTY.png?1622820503',
  // } as Token,
  // elon: {
  //   id: 'elon',
  //   address: '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
  //   symbol: 'ELON',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413',
  // } as Token,
  // ftm: {
  //   id: 'ftm',
  //   address: '0x4E15361FD6b4BB609Fa63C81A2be19d873717870',
  //   symbol: 'FTM',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016',
  // } as Token,
  // inj: {
  //   id: 'inj',
  //   address: '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
  //   symbol: 'INJ',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237',
  // } as Token,
  // ldo: {
  //   id: 'ldo',
  //   address: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
  //   symbol: 'LDO',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644',
  // } as Token,
  // mim: {
  //   id: 'mim',
  //   address: '0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3',
  //   symbol: 'MIM',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612',
  // } as Token,
  // mc: {
  //   id: 'mc',
  //   address: '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
  //   symbol: 'MC',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154',
  // } as Token,
  // gala: {
  //   id: 'gala',
  //   address: '0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA',
  //   symbol: 'GALA',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png?1600233435',
  // } as Token,
  // cube: {
  //   id: 'cube',
  //   address: '0xDf801468a808a32656D2eD2D2d80B72A129739f4',
  //   symbol: 'CUBE',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861',
  // } as Token,
  // alice: {
  //   id: 'alice',
  //   address: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  //   symbol: 'ALICE',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968',
  // } as Token,
  // powr: {
  //   id: 'powr',
  //   address: '0x595832F8FC6BF59c85C527fEC3740A1b7a361269',
  //   symbol: 'POWR',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png?1547035082',
  // } as Token,
  // euroc: {
  //   id: 'euroc',
  //   address: '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c',
  //   symbol: 'EUROC',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/26045/thumb/euro-coin.png?1655394420',
  // } as Token,
  // abt: {
  //   id: 'abt',
  //   address: '0xB98d4C97425d9908E66E53A6fDf673ACcA0BE986',
  //   symbol: 'ABT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2341/thumb/arcblock.png?1547036543',
  // } as Token,
  // adx: {
  //   id: 'adx',
  //   address: '0xADE00C28244d5CE17D72E40330B1c318cD12B7c3',
  //   symbol: 'ADX',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540',
  // } as Token,
  // aleph: {
  //   id: 'aleph',
  //   address: '0x27702a26126e0B3702af63Ee09aC4d1A084EF628',
  //   symbol: 'ALEPH',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725',
  // } as Token,
  // ali: {
  //   id: 'ali',
  //   address: '0x6B0b3a982b4634aC68dD83a4DBF02311cE324181',
  //   symbol: 'ALI',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848',
  // } as Token,
  // alpha: {
  //   id: 'alpha',
  //   address: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
  //   symbol: 'ALPHA',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876',
  // } as Token,
  // ast: {
  //   id: 'ast',
  //   address: '0x27054b13b1B798B345b591a4d22e6562d47eA75a',
  //   symbol: 'AST',
  //   decimals: 4,
  //   asset: 'https://assets.coingecko.com/coins/images/1019/thumb/Airswap.png?1630903484',
  // } as Token,
  // ata: {
  //   id: 'ata',
  //   address: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
  //   symbol: 'ATA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745',
  // } as Token,
  // bit: {
  //   id: 'bit',
  //   address: '0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5',
  //   symbol: 'BIT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17627/thumb/rI_YptK8.png?1653983088',
  // } as Token,
  // boba: {
  //   id: 'boba',
  //   address: '0x42bBFa2e77757C645eeaAd1655E0911a7553Efbc',
  //   symbol: 'BOBA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/20285/thumb/BOBA.png?1636811576',
  // } as Token,
  // busd: {
  //   id: 'busd',
  //   address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  //   symbol: 'BUSD',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
  // } as Token,
  // c98: {
  //   id: 'c98',
  //   address: '0xAE12C5930881c53715B369ceC7606B70d8EB229f',
  //   symbol: 'C98',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904',
  // } as Token,
  // celr: {
  //   id: 'celr',
  //   address: '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667',
  //   symbol: 'CELR',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437',
  // } as Token,
  // chr: {
  //   id: 'chr',
  //   address: '0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2',
  //   symbol: 'CHR',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018',
  // } as Token,
  // cqt: {
  //   id: 'cqt',
  //   address: '0xD417144312DbF50465b1C641d016962017Ef6240',
  //   symbol: 'CQT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14168/thumb/covalent-cqt.png?1624545218',
  // } as Token,
  // dar: {
  //   id: 'dar',
  //   address: '0x081131434f93063751813C619Ecca9C4dC7862a3',
  //   symbol: 'DAR',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223',
  // } as Token,
  // dent: {
  //   id: 'dent',
  //   address: '0x3597bfD533a99c9aa083587B074434E61Eb0A258',
  //   symbol: 'DENT',
  //   decimals: 8,
  //   asset: 'https://assets.coingecko.com/coins/images/1152/thumb/gLCEA2G.png?1604543239',
  // } as Token,
  // dext: {
  //   id: 'dext',
  //   address: '0xfB7B4564402E5500dB5bB6d63Ae671302777C75a',
  //   symbol: 'DEXT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188',
  // } as Token,
  // dpi: {
  //   id: 'dpi',
  //   address: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
  //   symbol: 'DPI',
  //   decimals: 18,
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png?1600051053',
  // } as Token,
  // drep: {
  //   id: 'drep',
  //   address: '0x3Ab6Ed69Ef663bd986Ee59205CCaD8A20F98b4c2',
  //   symbol: 'DREP',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445',
  // } as Token,
  // dyp: {
  //   id: 'dyp',
  //   address: '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
  //   symbol: 'DYP',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13480/thumb/DYP_Logo_Symbol-8.png?1655809066',
  // } as Token,
  // ela: {
  //   id: 'ela',
  //   address: '0xe6fd75ff38Adca4B97FBCD938c86b98772431867',
  //   symbol: 'ELA',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/2780/thumb/Elastos.png?1597048112',
  // } as Token,
  // eul: {
  //   id: 'eul',
  //   address: '0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b',
  //   symbol: 'EUL',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/26149/thumb/YCvKDfl8_400x400.jpeg?1656041509',
  // } as Token,
  // fis: {
  //   id: 'fis',
  //   address: '0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d',
  //   symbol: 'FIS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg?1599730991',
  // } as Token,
  // fort: {
  //   id: 'fort',
  //   address: '0x41545f8b9472D758bB669ed8EaEEEcD7a9C4Ec29',
  //   symbol: 'FORT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696',
  // } as Token,
  // frax: {
  //   id: 'frax',
  //   address: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  //   symbol: 'FRAX',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506',
  // } as Token,
  // gal: {
  //   id: 'gal',
  //   address: '0x5fAa989Af96Af85384b8a938c2EdE4A7378D9875',
  //   symbol: 'GAL',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/24530/thumb/GAL-Token-Icon.png?1651483533',
  // } as Token,
  // ghst: {
  //   id: 'ghst',
  //   address: '0x3F382DbD960E3a9bbCeaE22651E88158d2791550',
  //   symbol: 'GHST',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/12467/thumb/ghst_200.png?1600750321',
  // } as Token,
  // hopr: {
  //   id: 'hopr',
  //   symbol: 'HOPR',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png?1614073468',
  //   address: '0xF5581dFeFD8Fb0e4aeC526bE659CFaB1f8c781dA',
  //   decimals: 18,
  // } as Token,
  // index: {
  //   id: 'index',
  //   symbol: 'INDEX',
  //   asset: 'https://assets.coingecko.com/coins/images/12729/thumb/index.png?1634894321',
  //   address: '0x0954906da0Bf32d5479e25f46056d22f08464cab',
  //   decimals: 18,
  // } as Token,
  // jam: {
  //   id: 'jam',
  //   symbol: 'JAM',
  //   asset: 'https://assets.coingecko.com/coins/images/24648/thumb/ey40AzBN_400x400.jpg?1648507272',
  //   address: '0x23894DC9da6c94ECb439911cAF7d337746575A72',
  //   decimals: 18,
  // } as Token,
  // jup: {
  //   id: 'jup',
  //   symbol: 'JUP',
  //   asset: 'https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932',
  //   address: '0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8',
  //   decimals: 18,
  // } as Token,
  // key: {
  //   id: 'key',
  //   symbol: 'KEY',
  //   asset: 'https://assets.coingecko.com/coins/images/2034/thumb/selfkey.png?1548608934',
  //   address: '0x4CC19356f2D37338b9802aa8E8fc58B0373296E7',
  //   decimals: 18,
  // } as Token,
  // loka: {
  //   id: 'loka',
  //   symbol: 'LOKA',
  //   asset: 'https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png?1642643271',
  //   address: '0x61E90A50137E1F645c9eF4a0d3A4f01477738406',
  //   decimals: 18,
  // } as Token,
  // lusd: {
  //   id: 'lusd',
  //   symbol: 'LUSD',
  //   asset: 'https://assets.coingecko.com/coins/images/14666/thumb/Group_3.png?1617631327',
  //   address: '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
  //   decimals: 18,
  // } as Token,
  // math: {
  //   id: 'math',
  //   symbol: 'MATH',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590',
  //   address: '0x08d967bb0134F2d07f7cfb6E246680c53927DD30',
  //   decimals: 18,
  // } as Token,
  // metis: {
  //   id: 'metis',
  //   symbol: 'METIS',
  //   asset: 'https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312',
  //   address: '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
  //   decimals: 18,
  // } as Token,
  // mona: {
  //   id: 'mona',
  //   symbol: 'MONA',
  //   asset: 'https://assets.coingecko.com/coins/images/13298/thumb/monavale_logo.jpg?1607232721',
  //   address: '0x275f5Ad03be0Fa221B4C6649B8AeE09a42D9412A',
  //   decimals: 18,
  // } as Token,
  // mtl: {
  //   id: 'mtl',
  //   symbol: 'MTL',
  //   asset: 'https://assets.coingecko.com/coins/images/763/thumb/Metal.png?1592195010',
  //   address: '0xF433089366899D83a9f26A773D59ec7eCF30355e',
  //   decimals: 8,
  // } as Token,
  // muse: {
  //   id: 'muse',
  //   symbol: 'MUSE',
  //   asset: 'https://assets.coingecko.com/coins/images/13230/thumb/muse_logo.png?1606460453',
  //   address: '0xB6Ca7399B4F9CA56FC27cBfF44F4d2e4Eef1fc81',
  //   decimals: 18,
  // } as Token,
  // mv: {
  //   id: 'mv',
  //   symbol: 'MV',
  //   asset: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17704.png',
  //   address: '0xAE788F80F2756A86aa2F410C651F2aF83639B95b',
  //   decimals: 18,
  // } as Token,
  // mxc: {
  //   id: 'mxc',
  //   symbol: 'MXC',
  //   asset: 'https://assets.coingecko.com/coins/images/4604/thumb/mxc.png?1655534336',
  //   address: '0x5Ca381bBfb58f0092df149bD3D243b08B9a8386e',
  //   decimals: 18,
  // } as Token,
  // nest: {
  //   id: 'nest',
  //   symbol: 'NEST',
  //   asset: 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539',
  //   address: '0x04abEdA201850aC0124161F037Efd70c74ddC74C',
  //   decimals: 18,
  // } as Token,
  // pond: {
  //   id: 'pond',
  //   symbol: 'POND',
  //   asset: 'https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png?1622515451',
  //   address: '0x57B946008913B82E4dF85f501cbAeD910e58D26C',
  //   decimals: 18,
  // } as Token,
  // prq: {
  //   id: 'prq',
  //   symbol: 'PRQ',
  //   asset: 'https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280',
  //   address: '0x362bc847A3a9637d3af6624EeC853618a43ed7D2',
  //   decimals: 18,
  // } as Token,
  // pstake: {
  //   id: 'pstake',
  //   symbol: 'PSTAKE',
  //   asset: 'https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930',
  //   address: '0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006',
  //   decimals: 18,
  // } as Token,
  // qrdo: {
  //   id: 'qrdo',
  //   symbol: 'QRDO',
  //   asset: 'https://assets.coingecko.com/coins/images/17541/thumb/qrdo.png?1630637735',
  //   address: '0x4123a133ae3c521FD134D7b13A2dEC35b56c2463',
  //   decimals: 8,
  // } as Token,
  // revv: {
  //   id: 'revv',
  //   symbol: 'REVV',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390',
  //   address: '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca',
  //   decimals: 18,
  // } as Token,
  // rook: {
  //   id: 'rook',
  //   symbol: 'ROOK',
  //   asset: 'https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506',
  //   address: '0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a',
  //   decimals: 18,
  // } as Token,
  // stg: {
  //   id: 'stg',
  //   symbol: 'STG',
  //   asset: 'https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518',
  //   address: '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
  //   decimals: 18,
  // } as Token,
  // swftc: {
  //   id: 'swftc',
  //   symbol: 'SWFTC',
  //   asset: 'https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022',
  //   address: '0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e',
  //   decimals: 8,
  // } as Token,
  // sxp: {
  //   id: 'sxp',
  //   symbol: 'SXP',
  //   asset: 'https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311',
  //   address: '0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9',
  //   decimals: 18,
  // } as Token,
  // sylo: {
  //   id: 'sylo',
  //   symbol: 'SYLO',
  //   asset: 'https://assets.coingecko.com/coins/images/6430/thumb/SYLO.svg?1589527756',
  //   address: '0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4',
  //   decimals: 18,
  // } as Token,
  // t: {
  //   id: 't',
  //   symbol: 'T',
  //   asset: 'https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg?1641220340',
  //   address: '0xCdF7028ceAB81fA0C6971208e83fa7872994beE5',
  //   decimals: 18,
  // } as Token,
  // time: {
  //   id: 'time',
  //   symbol: 'TIME',
  //   asset: 'https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666',
  //   address: '0x485d17A6f1B8780392d53D64751824253011A260',
  //   decimals: 8,
  // } as Token,
  // tlm: {
  //   id: 'tlm',
  //   symbol: 'TLM',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061',
  //   address: '0x888888848B652B3E3a0f34c96E00EEC0F3a23F72',
  //   decimals: 4,
  // } as Token,
  // tone: {
  //   id: 'tone',
  //   symbol: 'TONE',
  //   asset: 'https://assets.coingecko.com/coins/images/2325/thumb/tec.png?1547036538',
  //   address: '0x2Ab6Bb8408ca3199B8Fa6C92d5b455F820Af03c4',
  //   decimals: 18,
  // } as Token,
  // tvk: {
  //   id: 'tvk',
  //   symbol: 'TVK',
  //   asset: 'https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png?1656043619',
  //   address: '0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988',
  //   decimals: 18,
  // } as Token,
  // wampl: {
  //   id: 'wampl',
  //   symbol: 'WAMPL',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951',
  //   address: '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef',
  //   decimals: 18,
  // } as Token,
  // wcfg: {
  //   id: 'wcfg',
  //   symbol: 'WCFG',
  //   asset: 'https://assets.coingecko.com/coins/images/17106/thumb/WCFG.jpg?1626266462',
  //   address: '0xc221b7E65FfC80DE234bbB6667aBDd46593D34F0',
  //   decimals: 18,
  // } as Token,
  // woo: {
  //   id: 'woo',
  //   symbol: 'WOO',
  //   asset: 'https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367',
  //   address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
  //   decimals: 18,
  // } as Token,
  // xcn: {
  //   id: 'xcn',
  //   symbol: 'XCN',
  //   asset:
  //     'https://assets.coingecko.com/coins/images/24210/thumb/Chain_icon_200x200.png?1646895054',
  //   address: '0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18',
  //   decimals: 18,
  // } as Token,
  // ygg: {
  //   id: 'ygg',
  //   symbol: 'YGG',
  //   asset: 'https://assets.coingecko.com/coins/images/17358/thumb/le1nzlO6_400x400.jpg?1632465691',
  //   address: '0x25f8087EAD173b73D6e8B84329989A8eEA16CF73',
  //   decimals: 18,
  // } as Token,
  // socks: {
  //   id: 'socks',
  //   address: '0x23B608675a2B2fB1890d3ABBd85c5775c51691d5',
  //   symbol: 'SOCKS',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png?1582525244',
  // } as Token,
  // blur: {
  //   id: 'blur',
  //   address: '0x5283D291DBCF85356A21bA090E6db59121208b44',
  //   symbol: 'BLUR',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/28453/large/blur.png?1670745921',
  // } as Token,
  // axl: {
  //   id: 'axl',
  //   address: '0x467719aD09025FcC6cF6F8311755809d45a5E5f3',
  //   symbol: 'AXL',
  //   decimals: 6,
  //   asset: 'https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg',
  // } as Token,
  // ilv: {
  //   id: 'ilv',
  //   address: '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
  //   symbol: 'ILV',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/14468/large/ILV.JPG',
  // } as Token,
  // hft: {
  //   id: 'hft',
  //   address: '0xb3999F658C0391d94A37f7FF328F3feC942BcADC',
  //   symbol: 'HFT',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/26136/large/hashflow-icon-cmc.png',
  // } as Token,
  // arb: {
  //   id: 'arb',
  //   address: '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1',
  //   symbol: 'ARB',
  //   decimals: 18,
  //   asset: 'https://arbitrum.foundation/logo.png',
  // } as Token,
  // cbeth: {
  //   id: 'cbeth',
  //   address: '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704',
  //   symbol: 'cbETH',
  //   decimals: 18,
  //   asset: 'https://assets.coingecko.com/coins/images/27008/large/cbeth.png',
  // },
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgWPunksOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 14.3861V16.2656M5.78137 12.9187L6.05075 12.8331V11.5149M1.01562 16.2656C1.01562 15.1737 1.72259 14.2075 2.76328 13.877L4.38534 13.362M9.94925 11.5149V12.8331L10.2186 12.9187M11.6147 13.362L13.2367 13.877C14.2774 14.2075 14.9844 15.1737 14.9844 16.2656M4.21781 8.42566C4.52275 10.8361 6.78703 12.1986 8 12.1986C9.21297 12.1986 11.4772 10.8361 11.7822 8.42566M13.6075 11.3138C13.6075 8.03791 12.6679 3.79194 11.7032 2.60819L12.5274 1.71572C12.5274 1.71572 12.0977 1.52028 11.4113 1.30384M10.5071 1.05278C9.77372 0.876595 8.90228 0.734376 8 0.734376C7.36584 0.734376 5.65003 0.955032 4.30459 2.59891C3.33638 3.78188 2.3925 8.02931 2.3925 11.3138M5.16091 7.48897C3.75297 8.62928 2.3925 10.1545 2.3925 11.3138C2.3925 13.426 8 14.3861 8 14.3861C8 14.3861 13.6075 13.426 13.6075 11.3138C13.6075 9.20144 9.09053 5.87419 8 5.87419C7.61584 5.87419 6.80647 6.28709 5.91916 6.91428M6.87016 9.76063C7.02781 9.92722 7.51391 10.1699 8 10.1699C8.48609 10.1699 8.97219 9.92722 9.12984 9.76063M8.37956 7.48903C8.87694 8.64866 10.7231 8.94141 11.7821 8.42566M4.21791 8.42566C5.27688 8.94141 7.12306 8.64866 7.62044 7.48903M8.48609 9.02153C8.19019 9.14581 7.80981 9.14581 7.51391 9.02153C7.218 8.89728 7.13494 8.62103 7.24044 8.37503L7.62044 7.48903C7.75694 7.17078 8.24306 7.17078 8.37956 7.48903L8.75956 8.37503C8.86506 8.62103 8.782 8.89728 8.48609 9.02153V9.02153Z"
      stroke="currentColor"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgWPunksOutlined;

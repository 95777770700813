enum FunctionKey {
  // Queries
  GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER',
  GET_MARKETS = 'GET_MARKETS',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_ASSETS_IN_ACCOUNT = 'GET_ASSETS_IN_ACCOUNT',
  GET_XCN_ACCRUED = 'GET_XCN_ACCRUED',
  GET_XCN_REWARD = 'GET_XCN_REWARD',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_NFT_ALLOWANCE = 'GET_NFT_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_TOKEN_BALANCES = 'GET_TOKEN_BALANCES',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_XCN_WITHDRAWABLE_AMOUNT = 'GET_XCN_WITHDRAWABLE_AMOUNT',
  GET_O_TOKEN_CASH = 'GET_O_TOKEN_CASH',
  GET_O_TOKEN_BORROW_BALANCE = 'GET_O_TOKEN_BORROW_BALANCE',
  GET_O_TOKEN_BALANCE = 'GET_O_TOKEN_BALANCE',
  GET_O_TOKEN_BALANCES_ALL = 'GET_O_TOKEN_BALANCES_ALL',
  GET_O_TOKEN_DAILY_XCN = 'GET_O_TOKEN_DAILY_XCN',
  GET_O_TOKEN_INTEREST_RATE_MODEL = 'GET_O_TOKEN_INTEREST_RATE_MODEL',
  GET_O_TOKEN_APY_SIMULATIONS = 'GET_O_TOKEN_APY_SIMULATIONS',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_CURRENT_VOTES = 'GET_CURRENT_VOTES',
  GET_PRIOR_VOTES = 'GET_PRIOR_VOTES',
  GET_STAKING_INFOS = 'GET_STAKING_INFOS',
  GET_STAKING_APY = 'GET_STAKING_APY',
  GET_VOTERS = 'GET_VOTERS',
  GET_PENDING_XCN = 'GET_PENDING_XCN',
  GET_STAKE_HISTORIES = 'GET_STAKE_HISTORIES',
  GET_PROPOSALS = 'GET_PROPOSALS',
  GET_PROPOSAL_VOTES = 'GET_PROPOSAL_VOTES',
  GET_PROPOSAL = 'GET_PROPOSAL',
  GET_VOTE_RECEIPT = 'GET_VOTE_RECEIPT',
  GET_VOTE_DELEGATE_ADDRESS = 'GET_VOTE_DELEGATE_ADDRESS',
  GET_PROPOSAL_STATE = 'GET_PROPOSAL_STATE',
  GET_LATEST_PROPOSAL_ID_BY_PROPOSER = 'GET_LATEST_PROPOSAL_ID_BY_PROPOSER',
  GET_VOTER_ACCOUNTS = 'GET_VOTER_ACCOUNTS',
  GET_VOTER_DETAILS = 'GET_VOTER_DETAILS',
  GET_VOTER_HISTORY = 'GET_VOTER_HISTORY',
  GET_PROPOSAL_THRESHOLD = 'GET_PROPOSAL_THRESHOLD',
  GET_PROPOSAL_ETA = 'GET_PROPOSAL_ETA',
  GET_UNISWAP_PAIRS = 'GET_UNISWAP_PAIRS',
  GET_PROXIES = 'GET_PROXIES',
  GET_OWNED_PUNK_IDS = 'GET_OWNED_PUNK_IDS',
  GET_OWNED_WPUNKS_IDS = 'GET_OWNED_WPUNKS_IDS',
  GET_FARMS = 'GET_FARMS',
  GET_UNISWAP_OUT_AMOUNT = 'GET_UNISWAP_OUT_AMOUNT',
  ADD_LIQUIDITY = 'ADD_LIQUIDITY',

  // Mutations
  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_NFT_TOKEN = 'APPROVE_NFT_TOKEN',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  SUPPLY = 'SUPPLY',
  SUPPLY_ETH = 'SUPPLY_ETH',
  SUPPLY_NFT = 'SUPPLY_NFT',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  REDEEM_NFT = 'REDEEM_NFT',
  CLAIM_XCN_REWARD = 'CLAIM_XCN_REWARD',
  REPAY_NON_ETH_O_TOKEN = 'REPAY_NON_ETH_O_TOKEN',
  REPAY_ETH = 'REPAY_ETH',
  BORROW_O_TOKEN = 'BORROW_O_TOKEN',
  WITHDRAW_XCN = 'WITHDRAW_XCN',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  EXECUTE_PROPOSAL = 'EXECUTE_PROPOSAL',
  QUEUE_PROPOSAL = 'QUEUE_PROPOSAL',
  CANCEL_PROPOSAL = 'CANCEL_PROPOSAL',
  SET_VOTE_DELEGATE = 'SET_VOTE_DELEGATE',
  CAST_VOTE = 'CAST_VOTE',
  CAST_VOTE_WITH_REASON = 'CAST_VOTE_WITH_REASON',
  SWAP_TOKENS = 'SWAP_TOKENS',
  REGISTER_PROXY = 'REGISTER_PROXY',
  DEPOSIT_PUNK = 'DEPOSIT_PUNK',
  MINT_WPUNKS = 'MINT_WPUNKS',
  BURN_WPUNKS = 'BURN_WPUNKS',
  LIQUIDATE_WITH_SINGLE_REPAY_V2 = 'LIQUIDATE_WITH_SINGLE_REPAY_V2',
  LIQUIDATE_BORROW = 'LIQUIDATE_BORROW',
  STAKE_XCN = 'STAKE_XCN',
  CLAIM_XCN = 'CLAIM_XCN',
  STAKE_IN_FARM = 'STAKE_IN_FARM',
  WITHDRAW_FROM_FARM = 'WITHDRAW_FROM_FARM',
  CLAIM_FARM_REWARD = 'CLAIM_FARM_REWARD',
}

export default FunctionKey;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgXcn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_319_1897)">
      <path
        d="M1.77691 4.30197C5.86816 -1.54058 14.3857 -1.31768 18.1913 4.27531C16.2927 6.17708 14.3925 8.07941 12.4855 9.98911C14.4004 11.913 16.2967 13.8182 18.2196 15.7506C16.202 18.4441 13.5507 19.9466 10.1697 19.9988C6.66238 20.0532 3.90048 18.5575 1.79788 15.7818C3.72361 13.8573 5.62497 11.9572 7.53086 10.0521C5.58869 8.11117 3.6879 6.21111 1.77691 4.30197ZM13.2746 4.18797C11.2066 2.93676 8.35682 3.11769 6.74741 4.19988C7.83528 5.2883 8.92711 6.3807 10.0048 7.45891C11.0796 6.38353 12.1714 5.29114 13.2746 4.18797ZM6.73211 15.7897C7.89537 16.9524 11.6839 16.9666 13.2429 15.8288C12.151 14.7364 11.0547 13.6401 9.96792 12.5528C8.90557 13.6157 7.81884 14.703 6.73211 15.7897Z"
        fill="white"
      />
      <path
        d="M18.4822 15.2906C17.6931 14.4994 16.9176 13.7257 16.1506 12.9436C16.1046 12.8971 16.0927 12.7706 16.1205 12.7048C16.8739 10.9063 16.8733 9.11113 16.1216 7.31203C16.0916 7.24056 16.1086 7.10331 16.1591 7.05169C16.9142 6.28316 17.6783 5.52427 18.4408 4.76368C18.4521 4.75233 18.4737 4.75177 18.4895 4.74609C20.4414 7.66141 20.5672 12.0911 18.4822 15.2906Z"
        fill="white"
      />
      <path
        d="M1.51624 15.2849C-0.537607 12.1478 -0.471847 7.71528 1.51284 4.73303C1.5684 4.78238 1.62565 4.82719 1.67667 4.87823C2.37848 5.57927 3.07633 6.28428 3.78324 6.97908C3.90909 7.10272 3.93573 7.19574 3.86431 7.36533C3.12791 9.11338 3.13245 10.8688 3.85637 12.6186C3.93007 12.7967 3.91193 12.8965 3.7753 13.0303C3.02077 13.7711 2.27701 14.5232 1.51624 15.2849Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_1897">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgXcn;

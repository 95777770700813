import * as React from 'react';
import { SVGProps } from 'react';

const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_319_1906)">
      <path
        d="M10 0C4.478 0 0 4.478 0 10C0 15.522 4.478 20 10 20C15.522 20 20 15.522 20 10C20 4.478 15.522 0 10 0ZM14.5659 7.79694C14.5703 7.89536 14.5724 7.99423 14.5724 8.09357C14.5724 11.1266 12.2638 14.624 8.04184 14.6242H8.04199H8.04184C6.74561 14.6242 5.5394 14.2442 4.52362 13.5931C4.70322 13.6143 4.88602 13.6249 5.07111 13.6249C6.14655 13.6249 7.13623 13.2581 7.92191 12.6424C6.91711 12.6237 6.06995 11.9601 5.77759 11.0481C5.91751 11.075 6.0614 11.0896 6.20895 11.0896C6.41846 11.0896 6.6214 11.0614 6.81427 11.0088C5.76401 10.7985 4.97284 9.8703 4.97284 8.75885C4.97284 8.74847 4.97284 8.73917 4.97314 8.72955C5.28244 8.90152 5.63614 9.00497 6.01273 9.01657C5.39642 8.60535 4.9913 7.90237 4.9913 7.10602C4.9913 6.68549 5.10498 6.2915 5.30212 5.9523C6.43402 7.34116 8.12561 8.25455 10.0333 8.35052C9.9939 8.18237 9.9736 8.0072 9.9736 7.82715C9.9736 6.56006 11.0016 5.53207 12.2691 5.53207C12.9294 5.53207 13.5257 5.81116 13.9445 6.25732C14.4675 6.15417 14.9585 5.96313 15.4021 5.70023C15.2304 6.23596 14.8666 6.68549 14.3927 6.96976C14.857 6.91422 15.2995 6.79108 15.7108 6.60828C15.4036 7.06863 15.014 7.47299 14.5659 7.79694Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_1906">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgTwitter;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgXcnOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.42162 3.94154C4.69464 -0.732451 11.5087 -0.55413 14.5532 3.92022C13.0343 5.44161 11.5142 6.96346 9.98853 8.49121C11.5205 10.0303 13.0375 11.5544 14.5758 13.1003C12.9618 15.2551 10.8407 16.4571 8.13591 16.4989C5.33001 16.5424 3.12049 15.3459 1.4384 13.1253C2.97899 11.5857 4.50008 10.0657 6.0248 8.54158C4.47106 6.98887 2.94996 5.46884 1.42116 3.94154H1.42162ZM10.6198 3.85034C8.96539 2.84939 6.68557 2.99413 5.39804 3.85987C6.26834 4.7306 7.14181 5.60451 8.00394 6.46707C8.86381 5.60678 9.73728 4.73287 10.6198 3.85034ZM5.3858 13.1316C6.31641 14.0618 9.34726 14.0731 10.5944 13.1629C9.72095 12.289 8.84385 11.412 7.97446 10.5421C7.12458 11.3924 6.25519 12.2623 5.3858 13.1316Z"
      fill="currentColor"
    />
    <path
      d="M14.7858 12.7323C14.1545 12.0994 13.5341 11.4805 12.9205 10.8548C12.8838 10.8175 12.8742 10.7164 12.8965 10.6637C13.4992 9.22491 13.4987 7.78882 12.8974 6.34955C12.8733 6.29238 12.8869 6.18257 12.9273 6.14128C13.5314 5.52646 14.1427 4.91936 14.7527 4.31089C14.7618 4.30181 14.779 4.30136 14.7917 4.29682C16.3532 6.62906 16.4534 10.1728 14.7854 12.7323H14.7858Z"
      fill="currentColor"
    />
    <path
      d="M1.213 12.7278C-0.430087 10.2182 -0.377479 6.67216 1.21028 4.28639C1.25472 4.32586 1.30053 4.36171 1.34134 4.40254C1.9028 4.96337 2.46107 5.52737 3.02661 6.0832C3.12729 6.18212 3.1486 6.25653 3.09146 6.3922C2.50234 7.79064 2.50597 9.19497 3.08511 10.5948C3.14407 10.7372 3.12956 10.8171 3.02026 10.9242C2.41663 11.5168 1.82162 12.1184 1.213 12.7278Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgXcnOutlined;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgFarm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.46877 9.94452V7.07298C8.13442 7.02892 9.33083 6.82973 10.1564 6.00415C11.3299 4.83052 11.238 2.90633 11.2336 2.82499C11.2204 2.58643 11.0299 2.39594 10.7913 2.38278C10.7101 2.37825 8.78584 2.28638 7.61224 3.45998C7.48737 3.58485 7.37696 3.71829 7.27912 3.85701C7.10719 3.16611 6.78407 2.39422 6.18008 1.79023C4.77826 0.38841 2.47146 0.498627 2.37399 0.504064C2.13543 0.51722 1.94493 0.707717 1.93178 0.946275C1.9264 1.0438 1.81612 3.35061 3.21797 4.75246C4.24499 5.77947 5.75746 5.99487 6.53129 6.03453V9.94452C6.68651 9.92709 6.843 9.91809 7.00003 9.91809C7.15709 9.91809 7.31356 9.92712 7.46877 9.94452Z"
      fill="currentColor"
    />
    <path
      d="M10.7653 12.7384C10.5132 12.7384 10.265 12.7786 10.0288 12.8565C9.81703 12.362 9.48441 11.9207 9.06117 11.5805C8.47949 11.1131 7.74741 10.8556 6.9998 10.8556C6.25222 10.8556 5.52014 11.1131 4.93843 11.5805C4.51519 11.9207 4.18257 12.362 3.97079 12.8565C3.73464 12.7786 3.48636 12.7384 3.2343 12.7384C1.9377 12.7384 0.882812 13.7933 0.882812 15.0899V16.0313C0.882812 16.2902 1.09268 16.5 1.35155 16.5H12.648C12.9069 16.5 13.1168 16.2902 13.1168 16.0313V15.0899C13.1168 13.7933 12.0619 12.7384 10.7653 12.7384Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFarm;
